table {
    border:0;
    width:100%!important;
    max-width:100%!important;
    tbody {
        border:1px solid $border1;
            tr {
                td {
                    padding:$p5;
                    & + td {
                        border-left:1px solid $border1;
                    }
                }
                &:nth-of-type(odd) {
                    background:$lightColor;   
                }
            }
    }

}