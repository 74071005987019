.includeFixed {
    display:block;
    position:fixed;
    top:0;
    left:0;
    right:0;
    overflow:visible;
    z-index:99999999999999999999999;
    .includeRel {
        display:block;
        position:relative;
        height:100%;
        width:100%;
        overflow:visible;
        z-index:1;
    }
}

.sharesWrapper,
.searchWrapper {
    position: absolute;
    z-index: 9999999999999999999999;
    top: -310px;
    left: 50%;

    display: block;

    width: 360px;
    height: 310px;
    margin-left: -180px;
    padding: $p3;

    transition: all .3s ease;

    opacity: 0;
    border: 1px solid $border1;
    background: $navBG;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.5);

    .navopen & {
            transform: translateX(-270px);
    }

    @include respond-to(mob-portrait) {
        width:100%;
        margin:0;
        left:0;
        padding:0;
        border-left:0;
        border-right:0;
        box-shadow:none;
    }

    .sharestrigger,
    .searchtrigger {
        line-height: 10px;

        position: absolute;
        top: 4px;
        right: 3px;

        display: block;

        width: 10px;
        height: 10px;

        color: $lightColor;

        @include respond-to(mob-portrait) {
            display:none;
        }
    }

    .innerWrapper {
        padding: $p5;
        background: $sectionLight;
    }
}

    .shares .sharesWrapper {
        top: 115px;
        opacity: 1;
        @include respond-to(mob-portrait) {
            top:108px;
            height:279px;
/*             position:relative; */
        }
    }

    .search .searchWrapper {
        top: 115px;
        opacity: 1;
        @include respond-to(mob-portrait) {
            top:108px;
            padding:$p4;
        }
    }

.searchWrapper {
	height:64px;
	@include respond-to(mob-portrait) {
        height:48px;
    }
	.innerWrapper {
        padding: 0;
        background: transparent;
        form > div {
            display:inline-block;
            input {
                border:0;
                width:100%;
            }
        }
        .jf-input-type-text {
            width:234px;
            margin-right:$p4;
            @include respond-to(mob-portrait) {
                width:212px;
            }
            
        }

        .jf-input-type-submit {
            width:80px;
        }
    }
}



#sharePrice big {
    font-size: 38px;
    font-weight: $regFont;
    line-height: 42px;

    position: relative;

    display: block;

    margin: 0;
    padding-top: $p5;

    color: $baseColor;
    border-top: 1px solid $border1;
}
#sharePrice big span {
    font-size: 60%;

    color: $darkColor;
}
#sharePrice dl {
    float: left;

    width: 100%;
    margin: 0;
    padding: 0;

    border-bottom: 1px solid $border1;
}
#sharePrice dl dt {
    font-weight: normal;

    float: left;
    clear: left;

    width: 50%;
    margin: 0;
    padding: 2px 0;

    color: $darkColor;
    border-top: 1px solid $border1;
}
#sharePrice dl dd {
    float: left;

    width: 50%;
    margin: 0;
    padding: 2px 0;

    border-top: 1px solid $border1;
}
#sharePrice a {
    position: relative;
    /*  color:#09C;
	 font-size:0.8em;  */

    display: inline-block;
    clear: both;

    padding: $p5 26px $p5 0;
    &:after {
        @include after($arrowRed);
    }
}

.sharesWrapper .heading {

    position: relative;

    display: block;

    margin: 1px;
    padding: $p5 0;

    color: $baseColor;
    line-height:20px;

}
.sharesWrapper .heading small {


    color: $baseColor;
}
.sharesWrapper .heading i {
    position: relative;

    display: block;
    float: left;
    overflow: hidden;

    width: 36px;
    height: 36px;
    margin: 1px 9px 0 1px;

    text-indent: 20px;

    background: transparent $rss center center no-repeat;
}


