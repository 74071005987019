#modal-terms{
    .modal-contentWrap{
        max-width:1170px;
        position:relative;
        display:inline-flex;
        flex-direction: column;
    }
    .modal-content{
        font-family: proxima-nova;
        font-size: 15px;
        line-height: 1.27;
        color: #4a4a4a;
        padding:48px 70px 32px;
        text-align: left;
        position:relative;
        overflow:auto;
        flex-basis:90%;
        // &:after{
        //     content:'';
        //     left:0;
        //     right:0;
        //     bottom:0;
        //     height:15%;
        //     background:linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
        //     display:block;
        //     position:absolute;
        // }
        
    }
    .modal-footer{
        background:#55565a;
        padding: 32px 64px;
        text-align:right;
        flex-basis:10%;
        max-height:10%;

        .btn_cta,.btn_cancel{
            padding:18px;
            text-align:center;
            text-transform: capitalize;

            font-family: proxima-nova;
            font-size: 14px;
            font-weight: 600;
            appearance:none;
            border:0 none;
            min-width: 194px;
        }
        .btn_cta{
            // background:#be1e2d;
            // color:#f6f6f6; 
            margin-right:16px;
        }
        .btn_cta,.btn_cancel{
            background-color:#f6f6f6;
            color:#55565a;
            transition:background-color 0.3s ease;
            &:hover{
                background-color:#be1e2d;
                color:#f6f6f6; 
            }
        }
    }
    h3{
        font-size: 14px;
        font-weight: 300;
        color: #4a4a4a;
    }
    h3.pleaseRead{
        font-size: 24px;
        font-weight: 300;
        line-height: 0.58;
        color: #be1e2d;
        padding-left:28px;
        border-left:1px #be1e2d solid;
    }
    h4{
        font-weight:400;
        font-size:14px;
    }
}

#modal-terms.modal-wrap.active .modal-contentWrap{
    padding:0;
}