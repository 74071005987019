/*banner*/
#banner-wrap {
    position: relative;

    min-height: 465px;
    
    @include respond-to(tab) {
        min-height:285px;
    }
    
    .banner-text {
        font-size: $bannerfontSize;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 32px 0;

        color: $lightColor;
        border-top: 1px solid $border1;
        text-align: left;

        .narrow {
            padding:0 100px 0 32px;

            a {
                color:$lightColor;
                display:inline-block;
                position:relative;
                padding-right:26px;
                &:after {
                    @include after($arrowRed);
                }

            }

            @include respond-to (mob) {
                span {
                    display:none;
                }
            }
        }

    }
}



.flexSlider.fill ul li {
    background-position: top center;
}
.flexSlider-dots {
    // display: none;
}

.flexSlider-nextButton,
.flexSlider-prevButton {
    width: 50px;

    opacity: .4;
}
#banner-wrap .flexSlider-nextButton:after,
#banner-wrap .flexSlider-prevButton:after {
    font-size: 40px;
    line-height: 40px;

    left: 0;

    width: 40px;
    height: 40px;
    margin-top: -40px;

    border: none;
    background-color: #445056;
    text-indent: 0;
}
#banner-wrap .flexSlider-nextButton:after {
    right: 0;
    left: auto;
}

@media only screen and (max-width: 768px) {
    #banner-wrap {
        // min-height: 500px;
    }
    #banner-wrap .banner-text {
        // font-size: 24px;
        // line-height: 26px;
    }

}

@media only screen and (max-width: 568px) {

    #banner-wrap {
        // min-height: 320px;
    }
    #banner-wrap .banner-text {
        // padding:16px;
        // font-size: 22px;
        // line-height: 24px;
    }
}