/*header*/
header#nav {
    
}
.fixed-header {
    position: fixed;
    .ie9 & {
        position:absolute;
/*         overflow-y:hidden;
        -ms-overflow-style:none; */
    }
}

.logo {
    /*width: 226px;*/
    /*min-width: 226px;*/
/*     height: 46px; */
}
.logo a {
    display: block;
        /*width: 226px;*/
    /*min-width: 226px;*/
/*     height: 46px; */
}
.logo a svg {
    /*width: 100%;*/
}
.s0 {
    fill: #231f20;
}


/*nav*/


.menu-button {
    margin-right: 23px;
    order:1;
    @include respond-to(mob) {
        margin-right:7px;
    }
}
.menu-button > span span,
.menu-button > span:before,
.menu-button > span:after {
    background-color: $navColor;
}

@media only screen and (max-width: 960px) {
    // .ie9 nav a {
    //     padding-left:$p4;
    // }
}

@media only screen and (max-width: 845px) {


    nav a {
        padding-left:$p4;
    }

    // .ie9 nav a {
    //     padding-left:0px;
    //     padding-right:10px;
    // }
}

@media only screen and (max-width: 800px) {
        nav a {
        padding-left:4px;
    }   
}

@media only screen and (max-width: 767px) {


    .flex-ul {
/*         box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5); */
/*         background:white; */
    }


    nav ul li a {
        padding:12px;
        .ie9 & {
            padding:24px 0;
        }
    }

    .ie9 nav ul li ul li a {
        padding:12px 0;
    }

    nav ul li ul {
        overflow: visible;
        width: auto;
/*         max-height: 600px;
        opacity:1; */
        padding:0 0 $p4 0;

    }


/*     nav ul li ul li a {
        background-color: #f5f5f5;
        font-size: 15px;
        line-height: 15px;
        color: inherit;
        padding:10px;
        width: auto;
        text-align:center;
    }
    nav ul li ul li a:hover {
        color: #c13139;
        background-color: #f5f5f5;
    } */

}
