/*!
 *  Stunnplate style.css
 *  Version: 0.8
 */
/*
 *  Notes:
 *  Main CSS file to include entire library followed by custom styles.
 * 
 */



/*-------------------------------------------------------*/
/*- FONTS                                               -*/
/*-------------------------------------------------------*/
/*MG Fonts have to go first! */
@import "fonts";

/*-------------------------------------------------------*/
/*- IMPORT LIBRARY                                      -*/
/*-------------------------------------------------------*/

/* Normalise */
@import "../../../lib/core/normalize";

/* Core */
@import "../../../lib/core/default";

@import "variables";
@import "mixins";

/* Modules */
@import "../../../lib/simple-fixed-header/simple-fixed-header";
/*@!import "../../../lib/fixedHeader/fixedheader";*/
/*@!import "../../../lib/layout/layout";*/
@import "../../../lib/layout/flexlayout";
/*@!import "../../../lib/nav/nav";*/
@import "../../../lib/nav/flex-nav";
@import "../../../lib/tabbed/tabbed";
@import "../../../lib/modal/modal";
@import "../../../lib/carousel/carousel";
/*@!import "../../../lib/almostInView/almostInView";*/
@import "../../../lib/maps/maps";
@import "../../../lib/flexSlider/flexSlider";


/* Vendor */
/* No vendor CSS as yet */

/*-------------------------------------------------------*/
/*- THEME - Theme Name replace demo-theme with theme    -*/
/*-------------------------------------------------------*/
@import "stunnplate";

@import "layout";
@import "header";

@import "banner";

@import "form";
@import "maps";
@import "table";

@import "footer";

@import "shares";

@import "assoc";

@import "termsModal";


/*-------------------------------------------------------*/
/*-     PRINT STYLES                                    -*/
/*-------------------------------------------------------*/
@import "../../../lib/core/print";