/*!
*  Stunnplate nav.css
*  Version: 1
*/
/*
*  Last Updated: 11/11/15
*  By: Adam
*  
*  Notes:
*  Flex nav layout
*
*  Dependencies:
*  core/default.1.1.css; - Requires Show/Hide Media Queries
* 
*/

/*-------------------------------------------------------*/
/*- MAIN MENU                                           -*/
/*-------------------------------------------------------*/


.flex-nav {
    display: flex;
    flex-direction: column;

    height: 109px;
    padding: 0 $p2;
    background-clip:content-box;

    background-color: $navBG;

    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    font-size:$navfontSize;
    line-height:$navlineHeight;

    @include respond-to(customNavFont){
    	font-size:13px;
    }
    
    @include respond-to(customNav) {
		padding:0;
		.ie9 & {
			padding: 0 $p2;
		}
    }

    @include respond-to(tab) {
		flex-direction:row;
        
        flex-wrap:wrap;
        align-items:flex-end;


    }

    .ie9 & {
    	display:block;
    	min-width:1024px;
    }

    .logo {
        line-height: 0;

        width: 212px;
        padding: $p2;

        border-right: 1px solid $border1;

        order:1;

        a {
            display: inline-block;
            padding: 0;
        }

        @include respond-to(tab) {
			flex:1 1 50%;
			padding: $p3 $p2;
			.ie9 & {
				padding:$p2;
				border-right:1px solid $border1;
			}
			border:0;
			text-align:left;
			img {
				width:123px;
				height:38px;
				.ie9 & {
					width:147px;
					height:45px;
				}
			}
        }

        @include respond-to(mob) {
        	text-align:center;
        	padding:$p3 0 $p3 $p3;
        	margin-right:-34px;
        }

        .ie9 & {display:inline-block}
    }

    .topbar {
        flex-grow:1;
        display: flex;

        border-bottom: 1px solid $border1;

        justify-content: space-between;
        align-items:center;

        width: calc(100% - 212px);
        padding:12px $p2;

        order:1;

        @include respond-to(tab) {
            width:100%;
            order:0;
        }

        @include respond-to(mob) {
        	padding:12px $p3;
        }

        a {
            color: $navColor;
            &.searchtrigger {
            	width:15px;
            	height:15px;
            	display:inline-block;
            	background:transparent $search right center no-repeat;
            	background-size:15px 15px;
            	text-indent:-9999px;
            	.ie9 & {
            		float:right;
            	}
            }
        }

        .ie9 & {display:inline-block; width:calc(100% - 220px); vertical-align:top;}
    }
}


.flex-ul {
    flex:none;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 212px);
    padding:0 $p2;
    order:1;

    @include respond-to(customNav) {
        display:none;
        .ie9 & {display:table;}
    }
}



.ie9 .flex-ul {
    display: table;

    text-align: right;
    vertical-align: middle;
	width:748px;
	min-width:748px;
/*     float:left; */
    vertical-align:top;
    margin-top:-66px;
    margin-left:212px;
	font-size:12px;

	
}
.ie9 .flex-ul li {
    display: table-cell;
    width:12%;
    text-align:center;
    &.homelink {
    	display:none;
    }

    & a {
    	text-align:center;
    	white-space:nowrap;
    }
}

.menu-button {
    position: relative;

    width: 48px;
    height: 48px;


    flex: 0 0 auto;

     align-self: flex-end;
     display:none;
     cursor:pointer;

    @include respond-to(customNav) {
        display:block;
        margin:9px 23px 9px 0;
        .ie9 & {display:none;}
    }
    .ie9 & {
    	display:none;
    	position:absolute;
    	width:0;
    	height:0;
    	overflow:hidden;
    	line-height:0;
    }
}

.menu-button span {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    padding: 0;

    color: white;
}
.menu-button span span {
    position: absolute;
    top: 50%;

    display: block;
    overflow: visible;

    width: 30px;
    height: 2px;
    margin: -2px auto 0 auto;

    transition: opacity .25s ease-in-out;

    background: white;
}


.menu-button > span:before,
.menu-button > span:after {
    position: absolute;
    left: 50%;

    width: 30px;
    height: 2px;
    margin-left: -15px;

    content: '';
    transition-timing-function: cubic-bezier(.445, .05, .55, .95);
    transition-duration: 200ms;
    transform: translate3d(0px, 0px, 0px);

    background: white;
}


.menu-button > span:before {
    top: 12px;
}
.menu-button > span:after {
    bottom: 14px;
}

.menu-button.close span span {
    opacity: 0;
}
.menu-button.close > span:before {
    top: 22px;
    transform: rotate(45deg) translateZ(0);
}
.menu-button.close > span:after {
    bottom: 24px;
    transform: rotate(-45deg) translateZ(0);
}

.menu-button a {
    font-size: 0;
    line-height: 0;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 48px;
    height: 48px;
    padding: 0;

    cursor: pointer;

    color: white;
}

#nav {
    margin: 0;
    padding: 0;

    background-color: transparent;
}

nav ul.container {
    margin-bottom: 0;
}


nav ul {
    /*width: 100%;*/
    margin: 0;
    padding: 0;
  
}

nav ul li {
    position: relative;
    /*overflow: hidden;*/

    max-height: none;

    transition: all 1s ease 0s;

    -mob-transition: all 1s ease 0s;
}

nav ul li a {
    color:$lightColor;
    display: inline-block;
    padding:24px 0;
    text-align: center;
    text-decoration: none;
    text-transform:uppercase;
}

nav a:hover {
    color: $navColor;
}

nav a:visited {

}


nav ul li ul {
	
    display: block;
    overflow: hidden;
    max-height: 0;
    opacity:0;
    padding-top:0;
    transition        : all 0.2s ease-in;
}

.flex-nav ul li ul {
	position: absolute;
	right:50%;
	transform: translateX(50%);

    width: 280px;

    background-color: $navOverlay;
    .ie9 & {
    	display:block;
    }

}

.ie9 .flex-nav ul li ul li {
	display:block;
	width:100%;
}

.flex-nav ul li a:focus + ul,
.flex-nav ul li:hover ul,
.flex-nav ul li.focussed ul {
    max-height: 600px;
    opacity: 1;
}


nav ul li ul li a {
	background:transparent;
    font-size: $fontSize;
    // line-height: $fontSize;
    color: $navColor;
    padding:10px $p3;
    width: 100%;
    text-align:left;
    text-transform:none;
    text-align:center;
    .ie9 & {
    	display:block;

    }
}
nav ul li ul li a:hover {
    color: $navBG;
    background-color: $navColor;
}



#pushnav {
	position: fixed;
	width:270px;
	top:44px;
	bottom:0;
	right:0;
	background:$navDark;
	transform: translateX(100%);
	transition: transform .3s;
	z-index:99999999999999999;
	padding:$p3;
    -ms-overflow-style:none;
    
    nav {
        display:block;
        position:absolute;
        top:66px;
        right:16px;
        width:238px;
        height:100vh;
        padding-bottom:200px;
        overflow-y:scroll;
    }
	> nav > ul {
        position: relative;
        display: block;
		margin-bottom:200px;
        
	}

	&.open > nav > ul {
/*         	overflow-y:scroll!important; */
        }

	ul a {
		padding:$p3 0;
	}

	ul li:first-of-type a {
			padding-top:0;
		}
	ul li ul li {
		text-align:left;
		
		a {
			text-align:left;
			padding:$p4 $p4 $p4 $p3;
			&:first-of-type {
				padding-top:$p4;
			}
		}
	}
	ul li.open ul {
		max-height:600px;
		opacity:1;
	}

	.menu-button {
		margin:-8px $p3 12px 206px;
	}

}

#pushnav.open {
	display:block;
	transform: translateX(0%);
}



#banner-wrap,
#footer,
#main,
header .menu-button,
header .logo {

    transform: translateX(0%);
    transition: transform .3s;
}

.navopen {
	#banner-wrap,
	#footer,
	#main,
	header .menu-button  {

	    transform: translateX(-270px);
	    
	}
	@include respond-to(mob) {
		header .logo {
			 transform: translateX(-270px);
		}
	}
}
