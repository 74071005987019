/*default and layout */

body {
	font-family: $mainFont;
	font-size: $fontSize;
	font-weight: $thinFont;
	line-height: $lineHeight;

	color: $baseColor;
	background-color: $lightColor;
	@include respond-to(tab) {
		font-size: 14px;
	}
}

a {
	text-decoration: none;

	color: $highlight;
	transition        : color .2s ease-in, background-color 0.2s ease-in;
	&.arrow {display:block; position:relative;
		&:after {
			@include after($arrowRed);
		}
	}
}

a:hover {
	color: $highlight;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h0,
.title {

	font-size: $introfontSize;
	font-weight:$thinFont;


}

.img-wrap img {
	width: 100%;
	max-width: 100%;
}

.carousel ul {
	border-top: none;
}



#company {
	.sTitle {
		padding-right:150px;
		position: relative;
		display: block;
		.logo {
			width:130px;
			display: block;
			position: absolute;
			top:0;
			right:0;
			img {
				display: block;
				width:100%;
				height:auto;
			}
		}
	}
}


/*services*/
.services .service {
	position: relative;

	min-width: 270px;

	text-align: left;
}

.ie9 .services .service {
	width:33.33%;
}
.services .service .img-wrap {
	border: 1px solid #808285;
}

#home .services .service {
	padding-bottom: 32px;
}
#home .services .service .img-wrap {
	margin-bottom: 32px;
}

.services .service h1 {
	font: 23px/24px 'Source Sans Pro', sans-serif;
	font-weight: 200;

	min-height: 48px;
}
.services .service .button {
	position: absolute;
	bottom: 16px;
	left: 16px;
}

.services .service .service-summary {
	display: inline-block;
	width:40%;
	padding-right:16px;
	padding-bottom:32px;
	vertical-align: top;
}

.services .service .service-summary + .img-wrap {
	display: inline-block;
	width:60%;
	margin-left:-4px;
	vertical-align: top;
}

.services .service .service-summary h3 {
	font-size: 18px;

	margin-top: 0;
}



#serviceAssocImagesCarousel {
	
}

#serviceAssocImagesCarousel.carousel li {
	width:100%;
}
#serviceAssocImagesCarousel .carousel-button-wrapper {
	position: absolute;
	top:50%;
	margin-top:-20px;
	width:100%;
}

#serviceAssocImagesCarousel a[href^='#carousel-button'] {
	margin:0;
	left:0;
	position: absolute;
}
#serviceAssocImagesCarousel a[href^='#carousel-button-next'] {
	left:auto;
	right:0;
}

.service-detail .block {text-align:left;}

/*projects*/
a[href^='#carousel-button'] {
	font-size: 0;

	position: relative;

	width: 32px;
	height: 32px;

	border: none;
	background: rgba(68,80,86,.65);
}
a[href^='#carousel-button']:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
}


#main section.dark a[href^='#carousel-button'] {
	background: rgba(255,255,255,.5);
}


/*#projectsCarousel ul {border-top:none;}*/
#projectsCarousel li {
	width: 33.33%;
}
#projectsCarousel li a {
	position: relative;

	display: block;

	text-decoration: none;
}
#projectsCarousel .project-content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	padding: 16px;
	padding-bottom: 42px;

	-webkit-transition: opacity .2s ease-in-out;
	   -moz-transition: opacity .2s ease-in-out;
		-ms-transition: opacity .2s ease-in-out;
		 -o-transition: opacity .2s ease-in-out;
			transition: opacity .2s ease-in-out;
	text-align: left;

	opacity: 0;
	color: white;
	background: rgb(68,80,86);
	background: rgba(68,80,86,.7);
}
#projectsCarousel li a:hover .project-content {
	opacity: 1;
}
#projectsCarousel .project-content h1 {
	font: 20px/22px 'Source Sans Pro', sans-serif;
	font-weight: 200;

	margin-top: 0;
}
#projectsCarousel .project-content p {
	font-size: 14px;
	line-height: 16px;
}
#projectsCarousel .project-content .button {
	position: absolute;
	bottom: 16px;
	left: 16px;

	color: #445056;
	background-color: white;
}
.img-wrap.vimeo {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 56.25%;
}
.img-wrap.vimeo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.projects .project {
	text-align: left;
}

.ie9 .projects .project {
	width:50%;
}

/*clients*/
#testimonialsCarousel li {
	width: 100%;
}
#testimonialsCarousel li p {
	padding: 0 32px;
}
#testimonialsCarousel li .testimonial {
	font-style: italic;

	position: relative;

	display: block;

	padding-top: 20px;
	padding-bottom: 20px;
}
#testimonialsCarousel li .testimonial:before,
#testimonialsCarousel li .testimonial:after {
	position: absolute;
	top: 0;
	left: 0;

	width: 32px;
	height: 32px;

	opacity: .5;
}
#testimonialsCarousel li .testimonial:after {
	top: auto;
	right: 0;
	bottom: 0;
	left: auto;
}
#testimonialsCarousel li .credit {
	font-size: 16px;

	text-align: right;
}

#clientsCarousel ul {
	padding-top: 15px;
}
#clientsCarousel li {
	width: 12.5%;
}
/*#clientsCarousel li > div {margin:0 8px;}*/
#clientsCarousel li .img-wrap {
	overflow: hidden;

	border-radius: 50%;
}

.flex-parent.clients {
	padding:0;
	overflow:hidden;
}
.flex-parent.clients .block.client {
	-webkit-flex: 0 1 25%;
	-ms-flex    : 0 1 25%;
	flex        : 0 1 25%;
	border-top: 1px solid #A1A7AA;
	border-left: 1px solid #A1A7AA;
	width: 25%;
	margin: -1px 0 0 -1px;
	min-width:100px;
	padding:0;
}

.ie11 .flex-parent.clients .block.client {
	-ms-flex-preferred-size: 22%;
	-webkit-flex-basis     : 22%;
	flex-basis             : 22%;
}

.ie10 .flex-parent.clients .block.client {
	-ms-flex-positive      : 0;
	-ms-flex-negative      : 1;
	-ms-flex-preferred-size: 22%;
}

.ie9 .flex-parent.clients {
	text-align: left;
}

.ie9 .flex-parent.clients .block.client {

}

/*About Us*/
#about #main section:nth-child(even) {
	background-color: white;
}
#about #main section:nth-child(odd),
#about #main section.alt {
	background-color: #f6f6f6;
}


.value + .value {
	border-top: solid 1px #d1d3d4;
}

.value:nth-child(2n) .img-wrap {
	-webkit-order : 2;
	-ms-flex-order: 2;
	order         : 2;
}

.value .value-text {
	text-align: left;
}
.value .value-text h3 {
	margin-top: 0;
	font-size: 18px;
}

.team {

}
.team-title {
	display: block;
	border-bottom: solid 1px #d1d3d4;
	padding-right:32px;
	position: relative;
	text-align: left;
	padding-bottom: 16px;
	margin-bottom: 16px;
}
.team-title .name {
	display: inline-block;
	width:100%;
	font-size: 22px;
	font-weight:400;
	text-transform: uppercase;
}
.team-title .job-title {
	display: inline-block;
	font-style: italic;
}
.team-title .email {
	position: absolute;
	right:0;
	top:50%;
	width: 32px;
	height:32px;
	margin-top: -16px;
	font-size: 0;
	line-height: 0;
}
.team .img-wrap {
	display: inline-block;
	width:30%;
	border: 1px solid #d1d3d4;
	vertical-align: top;
}
.team .team-summary {
	display: inline-block;
	width: 70%;
	margin-left:-4px;
	padding-left: 16px;
	vertical-align: top;
	text-align: left;
}



/*contact form*/
fieldset {
	text-align: left;
}
label {
	font-size: 15px;

	width: 100%;
	margin-bottom: 8px;

	.request-form & {
		margin-top:8px;
		margin-bottom:2px;
	}
}
input,
textarea {
	line-height: 30px;

	width: 100%;
	padding: 0 5px;

	border: 2px solid #bcbec0;
}

fieldset .flex-parent {
	padding: 0 16px;
}
fieldset .flex-parent .block {
	min-width: 0;
	min-height: 0;
	padding: 8px 16px;
}
fieldset .flex-parent.ends .block {
	min-width: 0;
}
fieldset .flex-parent.ends .block.submit {
	/*margin-left: auto;*/
}
fieldset .flex-parent.ends .block.submit input[class^='button'] {
	border: none;
	background-color: #c13139;
}
fieldset .flex-parent.ends .block.submit input[class^='button']:hover {
	background-color: #808286;
}

.ie9 input,
.ie10 input,
.ie11 input {
	min-height:30px;
}
.ie9 input.button {
	display: inline-block;
	position: relative;
	width: auto;
}

.ie9 textarea,
.ie10 textarea,
.ie11 textarea {
	min-height:100px;
}
.ie9 fieldset .flex-parent {
	display: table;
	width:100%;
}
.ie9 fieldset .flex-parent .block {
	display: table-cell;
	min-width: 50%;
}
.ie9 fieldset .flex-parent .block.submit {
	text-align: right;
}

#contact section.alt {
	z-index: 99;

	overflow: visible;

	padding-top: 0;
}
.map {
	z-index: 1;

	height: 470px;
}
.contact-wrap {
	z-index: 1;

	overflow: visible;

	padding-top: 0;
}
.contact-wrap .block.contact-form {
	padding-top: 50px;
}
.contact-wrap .block.contact-text {
	z-index: 1;

	max-width: 400px;
	margin-top: -75px;
	padding: 0 16px 16px 0;

	text-align: left;

	color: #e6e7e8;
	background-color: #445056;
}
.contact-wrap .block.contact-text .main-text {
	padding: 40px 32px 32px;
}
#main section .contact-wrap .block.contact-text .title-wrap h2 {
	width: 100%;
	margin-top: 30px;
	padding: 0 20px 20px 32px;

	border-color: #e6e7e8;
}

.ie9 .map {
	z-index: -1;
}
.ie9 .contact-wrap {
	display: table;

	margin-top: -75px;
	padding-right: 32px;
}
.ie9 .contact-wrap .block {
	display: table-cell;
}
.ie9 .contact-wrap .block.contact-form {
	width: auto;
	padding-top: 125px;
}
.ie9 .contact-wrap.flex-parent .block.contact-text {
	width: 400px;
	padding-right: 0;

	background-clip: border-box;
}
/*.ie9 .contact-wrap .block.contact-text .title-wrap h2 {width:100%; border-color: #e6e7e8;}*/





/*-------------------------------------------------------*/
/*- BUTTONS                                             -*/
/*-------------------------------------------------------*/
/* Button Types:
*
*   .button (General Stles Applied 
*   .button-cancel (Grey Outline Button)
*   .button-disabled (Light Grey Outline Button )
*   .button-search (White Outline button )
*/

span[class^='button'],
div[class^='button'],
a[class^='button'],
input[class^='button'] {
	/*font-family       :'Roboto Condensed',sans-serif;*/
	font-size: 17px;
	font-weight: 300;
	line-height: 28px;

	position: relative;

	display: inline-block;

	padding: 0 20px;

	-webkit-transition: all .2s ease-in-out;
	   -moz-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;
		 -o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
	text-decoration: none ;
	text-transform: uppercase;
	/*border            :1px solid;*/

	color: #fff;
	color: #fff;
	background-color: #445056;
}


div[class^='button']:hover,
a[class^='button']:hover,
input[class^='button']:hover {
	background-color: #808286;
	text-shadow: 0 0 2px rgba(255, 255, 255, .4);
}

a[class^='button'][class*='basic'],
div[class^='button'][class*='basic'] {
	border-color: #1fa0b7;
	background: #1fa0b7;
}
a[class^='button'][class*='cancel'],
div[class^='button'][class*='cancel'] {
	color: #bbb;
	border-color: #bbb;
}


/*-------------------------------------------------------*/
/*- MEDIA QUERIES                                       -*/
/*-------------------------------------------------------*/

/* ===============
	Maximal Width (above 1170px) - See Default
   =============== */


/*-------------------------------------------------------*/
/*- 1170 Desk Wide                                      -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 1170px) {
	/* ============
	WIDE: Main
   ============ */

	article {
	}

	aside {
	}
}

/*-------------------------------------------------------*/
/*- 1049                                                -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 1049px) {
	#projectsCarousel li {
		width: 50%;
	}
	#clientsCarousel li {
		width: 16.66%;
	}
}


/*-------------------------------------------------------*/
/*- 1029                                                -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 1029px) {

	.services .service .service-summary {
		width:100%;
		padding-right:0;
		padding-bottom:0;
	}

	.services .service .service-summary + .img-wrap {
		width:100%;
		margin-left:0;
	}
}


/*-------------------------------------------------------*/
/*- 845  Flex Row                                       -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 845px) {
	.flex-parent.services .block:first-of-type {
		min-width: 100%;
		-webkit-flex-basis     :100%;
		-ms-flex-preferred-size:100%;
		flex-basis             :100%;
	}

	.services .service .service-summary {
		display: inline-block;
		width:40%;
		padding-right:16px;
		padding-bottom:32px;
		vertical-align: top;
	}

	.services .service .service-summary + .img-wrap {
		display: inline-block;
		width:60%;
		margin-left:-4px;
		vertical-align: top;
	}

}


/*-------------------------------------------------------*/
/*- 768 Tab Portrait             		                -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 768px) {

	.map {
		height: 345px;
	}	
}


/*-------------------------------------------------------*/
/*- 767 Below Tab Portrait                              -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 767px) {
	#projectsCarousel li {
		width: 100%;
	}
	#clientsCarousel li {
		width: 20%;
	}


	.ie9 .flex-parent.clients .block.client {
		width:33.33%;
	}

	.flex-parent.clients .block.client {
		-webkit-flex: 0 1 33.33%;
	}

	.flex-parent .block.team {
		min-width:100%;
	}

}

/*-------------------------------------------------------*/
/*- 700                                                 -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 700px) {
	.contact-wrap .block {
		min-width: 100%;
	}
	.contact-wrap .block.contact-text {
		padding: 0 8px 8px 8px;

		-webkit-order : -1;
		-ms-flex-order: -1;
		order         : -1;
	}
}


/*-------------------------------------------------------*/
/*- 600   ie9 specific                                  -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 600px) {
	.ie9 .services .service {
		width:100%;
	}

	.ie9 .contact-wrap {padding:8px;}
	.ie9 .contact-wrap .block {display:table-cell;}
	.ie9 .contact-wrap .block.contact-form {padding-top:50px; width:100%; display:table-footer-group;}
	.ie9 .contact-wrap.flex-parent .block.contact-text {width:100%; padding:0 8px 8px 8px; background-clip:content-box;}

	.ie9 footer .flex-parent {
		display:block;
		width:100%;
	}
	.ie9 footer .flex-parent .block {
		display: block;
		width:100%;
		text-align: center;
	}

	.ie9 footer .flex-parent .block + .block  {
		text-align: center;
	}
	.ie9 footer .flex-parent .block + .block > div {
		text-align: center;
	}

}

/*-------------------------------------------------------*/
/*- 587 Mobile Safari fix                               -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 568px) {
	.services .service {
		min-width:100%;
		-webkit-flex-basis     :100%;
		-ms-flex-preferred-size:100%;
		flex-basis             :100%;
	}

	.ie9 .projects .project {
		width:100%;
	}
}

/*-------------------------------------------------------*/
/*- 568 Mobile                                          -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 568px) {


	#main section .narrow {
		width: 90%;
	}
	#clientsCarousel li {
		width: 33.33%;
	}
	.services .service .button {
		left: 8px;
		bottom: 8px;
	}
	.services .service .service-summary {
		width:100%;
		padding-right:0;
		padding-bottom:0;
	}

	.services .service .service-summary + .img-wrap {
		width:100%;
		margin-left:0;
	}

	.map {
		height:250px;
	}


	// footer .logo {margin:0 auto 32px;}

	// footer .flex-parent.ends .block {
	// 	min-width: 100%;
	// 	text-align: center;
	// 	-webkit-flex-basis     :100%;
	// 	-ms-flex-preferred-size:100%;
	// 	flex-basis             :100%;
	// }
	// footer .copyright {
	// 	text-align: center;
	// }

	.ie9 .flex-parent.clients .block.client {
		width:50%;
	}
}

/*-------------------------------------------------------*/
/*- Mid size Mobile                                     -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 450px) {
	.value .img-wrap {
		-webkit-order : 2;
		-ms-flex-order: 2;
		order         : 2;
	}
}

/*-------------------------------------------------------*/
/*- 320 Mobile                                          -*/
/*-------------------------------------------------------*/
@media only screen and (max-width: 329px) {
	#main section .narrow {
		width: 100%;
		padding: 0 16px;
	}
	#clientsCarousel li {
		width: 50%;
	}
	fieldset .flex-parent {
		padding: 0;
	}
	.flex-parent.clients .block.client {
		-webkit-flex: 0 1 50%;
	}

	.team .img-wrap {
		width:100%;
		margin-bottom: 16px;
	}
	.team .team-summary {
		width: 100%;
		margin-left:0;
		padding-left: 0;
	}
}
