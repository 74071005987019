/* apply a natural box layout model to all elements */
.carousel,
.carousel *,
.carousel:before,
.carousel:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
/* Resolve rendering bug in safari 6.0.5 */
.carousel ul,
.carousel li {
    -webkit-backface-visibility: hidden;
}
.carousel {
    position: relative;

    display: block;
    overflow: hidden;

    max-width: 100%;
}
.carousel-title {
    display: block;
}

.carousel ul {
    display: block;
    overflow: hidden;

    min-width: 100%;
    margin: 0 -16px;
    padding: 5px 0;

    list-style: none;

    -webkit-transition: all 500ms;
       -moz-transition: all 500ms;
         -o-transition: all 500ms;
            transition: all 500ms;
    white-space: nowrap;
    text-indent: 0;

    border-top: 1px solid #EEEEEE;
}
.carousel li {
    display: inline-block;

    width: 25%;
    margin: 0;

    -webkit-transition: all 600ms;
       -moz-transition: all 600ms;
         -o-transition: all 600ms;
            transition: all 600ms;
    vertical-align: top;
    white-space: normal;

    border: 0 dotted red;
    background: none;
}
.carousel.resizing li {
    -webkit-transition: none;
       -moz-transition: none;
         -o-transition: none;
            transition: none;
}
.carousel li {
    width: 25%;
}
.carousel li > div {
    margin: 0 16px;
}
.carousel li > a {
    display: block;

    margin: 0 16px;
}

.carousel-button-wrapper {
    position: relative;

    display: block;
    float: right;
}

a[href^='#carousel-button'] {
    display: inline-block;

    margin: 0 0 5px 5px;
    padding: 5px;

    text-align: left;
    text-decoration: none;

    border: 1px solid #666666;
    background-color: #EEEEEE;
}
a[href^='#carousel-button'].frozen {
    cursor: not-allowed;

    opacity: .5;

    filter: alpha(opacity=50);
}

/* NEW Test style */
/*section {width:50%;}*/
#carouselTwo.carousel li,
#carouselFour.carousel li {
    width: 100%;
}
#carouselThree.carousel li a {
    opacity: .5;
    outline: 1px solid white;
}
#carouselThree.carousel li a.active {
    opacity: 1;
    outline: 1px solid white;
}

/*! Media queries... 
 * OR include the stunnplate/lib/vendor/elementQuery to use Element Queries (see below)
 */

@media only screen and (max-width: 768px) {
    .carousel li {
        width: 33.333%;
    }
}
@media only screen and (max-width: 630px) {
    .carousel li {
        width: 50%;
    }
}
@media only screen and (max-width: 400px) {
    .carousel li {
        width: 100%;
    }
    .carousel-title {
        display: none;
    }
}

/*! Example using Element Queries... 
 * @Requires https://github.com/tysonmatanich/elementQuery (stunnplate/lib/vendor/elementQuery.min.js) 
 * The following uses a JS element query plugin (insead of a media-query) to style the tabbed content when they are less than 300px wide.
 * The plugin looks for [min/max]-[width/height] space-separated attribute selectors eg: element[min-width~="200px"][max-width~="400px"]{background-color:red;}

.carousel[max-width~="500px"] li {
    width:33.333%;
}
.carousel[max-width~="360px"] li {
    width:50%;
}
.carousel[max-width~="360px"] .carousel-title {
    display:none;
}
.carousel[max-width~="200px"] li {
    width:100%;
}
*/
