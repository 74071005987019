@mixin after($image) {
    content:"";
    display:block;
    position:absolute;
    right:0;
    top:50%;
    transform: translatey(-50%);
    width:20px;
    height:15px;
    background:transparent $image center center no-repeat;
    background-size:100%;
}


/* $break-phone: 320px;
$break-mob: 568px;
$break-tab: 768px;
$break-desk: 1024;
$break-wide: 1170; */

@mixin respond-to($media) {
  @if $media == mob-portrait {
    @media only screen and (max-width: $break-phone) { @content; }
  }
   @else if $media == mob {
    @media only screen and (max-width: $break-mob) { @content; }
  }
  @else if $media == tab {
    @media only screen and (max-width: $break-tab) { @content; }
  }
   @else if $media == customOurReach {
    @media only screen and (max-width: $break-customOurReach) { @content; }
  }
   @else if $media == customNav {
    @media only screen and (max-width: $break-customNav) { @content; }
  }
  @else if $media == customNavFont {
    @media only screen and (max-width: $break-customNavFont) { @content; }
  }
  @else if $media == tab-landscape {
    @media only screen and (min-width: $break-desk) { @content; }
  }
   @else if $media == desk {
    @media only screen and (min-width: $break-wide) { @content; }
  }
}
