/***************** FONTS *******************/

$mainFont: 'proxima-nova', sans-serif;
$fontSize: 15px;
$lineHeight: 22px ;
$navfontSize: 14px;
$navlineHeight: 18px;
$thinFont: 300;
$regFont: 400; 
$boldFont: 600; 

$bannerfontSize:18px;

$introfontSize:24px;
$introlineHeight:28px;

$titlefontSize:14px;

/***************** FONT COLORS *******************/

$baseColor: #4A4A4A;
$darkColor: #9B9B9B;
$highlight: #BE1E2D;
$lightColor: #FFFFFF;


$navColor: #D0D2C5;

/***************** BACKGROUNDS *******************/

$navBG: #55565A;

$navOverlay: rgba( $navBG, .85 ) ;

$navDark: #393A3F;

$sectionDark: #9B9B9B;
$sectionDarker: #4A4A4A;

$sectionLight: #F6F6F6;

$sectionLightAlt: #EDEDED;

$border1: #979797;
$border2: #D0D2C5;
$border3: #E9E9E9;

/***************** ICONS *******************/

$arrow: url(/img/arrow.svg);
$arrowRed: url(/img/arrowRed.svg);
$search: url(/img/search.svg);
$rss: url(/img/rss.svg);

/***************** PADDING *******************/
$p1:64px;
$p2:32px;
$p3:16px;
$p4:8px;
$p5:4px;

$p1a:48px;
$p2a:24px;

/***************** MEDIA QUERIES *******************/

$break-phone: 320px;
$break-mob: 568px;
$break-tab: 768px;
$break-desk: 1024;
$break-wide: 1170;

$break-customNav: 1000px;
$break-customNavFont: 1100px;
$break-customOurReach: 810px;