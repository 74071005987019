body{
	position: relative;
	z-index:1;
}

#main {
	display:flex;
	align-items:stretch;
	flex-wrap:wrap;
	max-width:100%;
		overflow:hidden;
	.ie9 & {
		display:block;
		position:relative;
		
		min-width:1024px;
	}
}

.ie9 #nav {
/* 	overflow-x:hidden; */
}

.sTitle {
	font-size: $titlefontSize;
	font-weight:$thinFont;
	text-transform: uppercase;
	margin:0 0 32px 0;
}

.row {
	flex:1 1 100%;
/* 	display:block; */
	width:100%;
}


.flex {
	display:flex;
	justify-content: space-between;
	align-items: stretch;

	@include respond-to(mob) {
		flex-wrap:wrap;
	}

	.ie9 & {
		display:table;
		border-collapse:collapse;
		min-width:1024px!important;
		vertical-align:top;
	}
}

.block {
	display:inline-block;
	position:relative;
	width:40%;

	color: $navColor;

	.dark & {

		color:$baseColor;
	}

		&:before {
		display:block;

		position:absolute;
		
		background-color:$sectionDarker;
		top:0;
		bottom:0;
		
		content:"";
		right:-1033px;
		left:0;
		z-index:0;

		.ie9 & {
			display:inline-block;
			min-height:1000%;
			height:1000%;
/* 			bottom:-100px; */
		}

		.dark & {
			background-color:$sectionLight;
/* 			color:$baseColor; */
		}
		
	}


	&:first-of-type {
/* 		background-color: $sectionLight; */
		color: $baseColor;
		text-align:right;
		width:60%;


		.dark & {
/* 			background-color:$sectionDark; */
			color:$lightColor;
		}
		

		&:before {
			right:0;
			left:-1033px;
			background-color:$sectionLight;


			.dark & {
				background-color:$sectionDark;
			}
			.v-light & {
				background-color:$lightColor;
			}
			.light & {
				background-color:$sectionLight;
			}
			.v-dark & {
				background-color:$sectionDarker;
			}
		}

			
	}


	&.half {
		width:50%;
		.ie9 & {
			width:48%;
		}

	}	
	&.imgBlock {
		&.img1 {
			@include respond-to(mob) {
				min-height:250px;
			}
		}

	}
	&.imgBlock:before {
		background:$sectionDark url(/img/companies.jpg) left center no-repeat;
		background-size:cover;
		.ie9 & {
			background-position:left top;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./img/companies.jpg', sizingMethod='scale');
			-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/img/companies.jpg', sizingMethod='scale')";

		}
		.alt & {
			background-position:-400px center;
			background-color:$sectionLight;
			background-image:url(/img/reach.jpg);
			.ie9 & {
				background-position:-400px top;
				filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./img/reach.jpg', sizingMethod='scale');
			-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/img/reach.jpg', sizingMethod='scale')";
			}
			
		}


	}

	.alt & {
/* 		background-color: $sectionDarker;
		color: $navColor; */
			&.imgBlock .innerBounds {
				width:50%;
				min-width:400px;
				min-height:100%;
				height:100%;
				background-color:$sectionLight;
				color:$baseColor;
				font-size:$introfontSize;
				line-height:$introlineHeight;
				@include respond-to(customOurReach) {
					width:100%;
					min-width:100%;
				}
				#ukmap {
					display:none;
				}
				a {
					font-size:$bannerfontSize;
					display:inline-block;
					padding-right:26px;
					position:relative;
					&:after {
						@include after($arrowRed);
					}
				}

/* 				.ie9 & {
					float:right;
				}  */
			}
	}

/* 	.imgBlock + & {
		min-width:400px;
		background:$sectionLight;
		color:$baseColor;
		font-size:$introfontSize;
		line-height:$introlineHeight;

		@include respond-to(tab) {
			width:100%;
			min-width:100%;
		}

		a	{
			color:$highlight;
			display:inline-block;
			padding-right:$p2;
			position:relative;
			font-size:$bannerfontSize;
			&:after {
				@include after($arrow);

			}
		}
	} */

	@include respond-to(mob) {
		width:100%;
		&.half,
		&:first-of-type {
			width:100%;
		}
	}
	&.column {
		display:flex;
		justify-content:space-between;
		flex-direction:column;
		align-content:stretch;
	}
	.column & {
		width:100%;
		.ie9 & {
			display:block;
		}
		& .innerBounds {
			padding:$p1 $p2 $p1 $p1!important;
			@include respond-to(tab) {
				padding:$p2!important;
			}
		}

		&.dark {
			color:$navColor;
			&:before {
				background-color:$sectionDark;
			}
		}
		&.v-light {
			color:$baseColor;
			&:before {
				background-color:$lightColor;
			}
		}
		&.light {
			color:$baseColor;
			&:before {
				background-color:$sectionLightAlt;
			}
		}
		&.v-dark {
			color:$navColor;
			&:before {
				background-color:$sectionDarker;
			}
		}

		&:before {
			left:0;
			right:-1033px;
		}
	}
	
	.ie9 & {display:table-cell;}

	.imgWrap {
		#news  & {
			float:left;
			max-width:40%;
			margin:0 $p2 $p2 0;
			@include respond-to(tab) {
				max-width:50%;
				margin:0 $p3 $p3 0;
			}
			@include respond-to(mob) {
				max-width:100%;
				width:100%;
				margin:0 0 $p3 0;
			}
		}
		img {
			width:100%;
		}
	}
}


.innerBounds {
	text-align: left;
	width:100%;

	padding: $p1 $p2 $p1 $p1;
	display:inline-block;
	margin:0;
	z-index:1;
	position:relative;

	p img {
		// width:100%;
		max-width:100%;
		height:auto;
	}

	.flexbox & {
		overflow-x:scroll;
		-ms-overflow-style:none;
		overflow:-moz-scrollbars-none;
	}

	
	.ie9 & {
		display:block;
		min-height:100%;
		height:100%;
	}

	@include respond-to(tab) {
		padding:$p2;
	}
	@include respond-to(mob) {
		padding:$p3;
	}


	.block:first-of-type & {
		padding: $p1 $p1 $p1 $p2;
		@include respond-to(tab) {
			padding:$p2;
		}
		@include respond-to(mob) {
			padding:$p2 $p3;
		}
	}


	.intro {
		font-size: $introfontSize;
		line-height:$introlineHeight;
		font-weight: $thinFont;
		display:block;
		border-left:1px solid $baseColor;
		color:$highlight;
		padding-left:$p2;
		margin-bottom:$p2;

		.half:first-of-type & {
			color:$lightColor;
			border-color:$lightColor;
		}

		@include respond-to(mob) {
			font-size:20px;
			line-height:22px;
			margin-bottom:$p3;
		}
	}
}


.bList {
	list-style-type:none;
	padding:0;

	li {
		display:block;
		border-bottom:1px solid $border1;
		.alt & {
			border-bottom-color:$border2;
		}

		a {
			display:block!important;
			position:relative;
			padding:$p3 $p2 $p3 0;
			color:$darkColor;

			&:after {
				@include after($arrow);
				
			}

			.alt & {
				color:$navColor;
			}

			.arrowFill {
				fill:$darkColor;
			}
		}
	}

	.pagedResults {
		display:block;
		width:100%;
		padding:0;
		margin:0;
		text-align:right;
		li {
			border:0;
			display:inline-block;

			a {
				padding:$p4;
				&:after {
					display:none;
				}
			}
			&:last-of-type a {
				padding-right:0;
			}

			&.active {
				padding:$p4;
				font-weight:$regFont;
				&:last-of-type {
				padding-right:0;
			}
			}
		}
	}
}