
/* interactive map */
    #mapLayer {display:block; position:fixed; background:transparent url("/baseimages/blackalpha.png"); background:rgba(0,0,0,.7); height: 100%; width:100%; z-index:999999999; top:0; left:0;}
        #map_bounds {display:block; position:absolute; top:20%; left:20%; width:60%; height:60%;}
        #map_box {display:block; position:relative; width:100%; height:100%; padding:10px; border:0px solid #000; background-color:#aaa; /*border-radius:10px 10px 0 0;*/}
        #map_canvas {width:100%; height:100%;}
        #mapLayer form {display:block; position:absolute; top:100%; left:0; width:100%; text-align:left; color:#333; margin:-2px 0; padding:0px; border:0px solid #000; background-color:#aaa; /*border-radius:0 0 10px 10px;*/}
        #mapLayer form fieldset {margin:2px 10px 10px 10px;}
        #mapLayer form label {text-align:left; color:#333;}
        #mapLayer #closeMap {display:block; position:absolute; right:0; bottom:-22px; line-height:2em; float:right; background:#aaa; padding:0 10px;}

.maps {
    @include respond-to(mob) {
        display:none;
    }
}

.mapText {

    font-size:$introfontSize;
    line-height:$introlineHeight;
    display: inline-block;
    position: relative;
    width:60%;
    vertical-align:top;
    padding-top:$p3;
    #home & {
        width:100%;
    }
    a {
        color:$lightColor;
        display:inline-block;
        position:relative;
        padding-right:26px;
        font-size:$bannerfontSize;
        #home & {
           color:$baseColor;
        }
        @include respond-to(tab) {
            font-size:16px;

        }
        &:after {

            @include after($arrowRed);
        }
    }

    @include respond-to(tab) {
        width:100%;
        font-size:$bannerfontSize;

    }
}

#ukmap {
    display:inline-block;
    width:38%;
    position:relative;
    vertical-align:top;
    img {
        width:100%;
    }
    @include respond-to(tab) {
        display:none;
    }
}

address {
    font-style:inherit;
    p {
        font-size:$introfontSize;
        line-height:$introlineHeight;
        @include respond-to(tab) {
            font-size:$bannerfontSize;

        }
    }
}