ul.images.carousel {
    list-style-type: none;
    padding-left:0;
    margin:16px -16px;
    display:block;

    .modal-contentWrap & {
        margin:16px 0;
    }

    li {
        width:50%;
    
        .modal-contentWrap & {
            width:100%;
        }
        div span {
            width:100%;
            display:block;
            background-size:100%;
            background-repeat:no-repeat;

            img {
                display:block;
                width:100%;
                height:auto;
            }
        }
    }

    .carousel-button-wrapper {
        float:none;
        text-align:right;
        padding-right:16px;

        .modal-contentWrap & {
            padding-right:0;
        }
    }
}

ul.files.bList li a:after {

    width: 15px;
    height: 23px;
    background: transparent url(/img/arrowDown.svg) center center no-repeat;
}

.noFiles {
    display:none;
}