/*footer*/
footer .footer {
    color: $lightColor;
    background-color: $sectionDark;
    border-top: 5px solid $highlight;

    .block {
        background: transparent;
        width:40%;
        text-align: left;
        & + .block {
            background: transparent;
            width: 60%;
            text-align: right;
        }

        &:before {
            display:none;
        }

        @include respond-to(mob) {
            width:100%;
            text-align: center;
            & + .block {
                width:100%;
            }
        }

        .logo {
            padding:$p2;
            @include respond-to(mob) {
                padding:$p3;
            }
        }

        nav ul {
            list-style-type:none;
            padding:$p2 $p2 $p2 0;
            margin:0;
            text-align: right;
            @include respond-to(mob) {
                padding:0 $p3 $p3 $p3;
            }

            li {
                display:inline-block;

                a {
                    text-transform:none;
                    padding:$p3;
                    line-height:$fontSize;
                    @include respond-to(mob) {
                        padding:$p4!important;
                    }

                }
                &:last-of-type a {
                    padding-right:0;
                }

                @include respond-to(mob) {
                    width:100%;
                    text-align:center;
                }
            }
        }
    }
}
