legend {
	font-size:$introfontSize;
	font-weight:$thinFont;
	line-height:30px;
	display:block;
	border-left:1px solid $border1;
	padding-left:16px;
}

fieldset {
	
	padding:$p2 0;
	margin-bottom:$p2;
	& + fieldset {
		border-bottom:1px solid $border1;
	}
	&:last-of-type {
		border:0;
		padding-top:0;
		margin-top:$p5;
		text-align:right;
	}
}


label {
	padding:$p5 0;
	display:block;
	span {
	width:30%;
	display:inline-block;

	}
}



input,
textarea,
select {
	border:1px solid $border3;
	border-radius:0;
	line-height:30px;
	display:inline-block;
	padding:0 4px;
	background:$lightColor;
	label span + & {
		width:70%;
		@include respond-to(mob) {
			width:100%;
		}
	}
}

input,
select {
	height:30px;
}

input[type="submit"]{
	width:auto;
	background:$highlight;
	color:$lightColor;
	text-transform:uppercase;

}

select {
	appearance:none;
	padding-right:40px;
	.ie9 &,
	.ie10 &,
	.ie11 & {
		
		padding-right:0;
	}
}

.btn-control {
	text-align:right;
	padding-top:16px;
	input[type="submit"] {
		border:none;
	}
}

.request-form {
	p {
		margin-top:32px;
	}
}
